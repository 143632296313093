
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { graphql } from 'gatsby'
import { Container } from "react-bootstrap";
import Layout from "../components/Layout";
import ContactDetails from '../components/ContactDetails';
import SocialMedia from '../components/SocialMedia';

export default function Home({ data }) {
    return (
        <div>
            <Layout title="home">
                <Container>
                    <section id="home-icons" className="py-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-1 mb-1 text-center"></div>
                                <div className="col-md-10 mb-1 text-center">
                                    <a className="text-danger" href="/ENQUIRY FORM TEMPLATE.dotm">
                                        <i role="button" aria-label="button" className="fas fa-download fa-3x mb-2 text-dark"></i>
                                        <h3>Click here to download the Shanti-Aid General Equiries form.</h3>
                                    </a>
                                </div>
                                <div className="col-md-1 mb-1 text-center"></div>
                            </div>
                        </div>
                    </section>
                </Container>
                <ContactDetails />
                <SocialMedia />
            </Layout>
        </div>
    )
}

export const query = graphql`
  {
    eflogo: file(relativePath: { eq: "ef-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 600)
      }
    },
    eflogosm: file(relativePath: { eq: "ef-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400)
      }
    },
    gofundme: file(relativePath: { eq: "gofundme.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    },    
    smile: file(relativePath: { eq: "amazon-smile-uk.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    },  
    logoimage: file(relativePath: { eq: "shanti_aid.png" }) {
      childImageSharp {
        gatsbyImageData(width: 900)
      }
    },
    handimage: file(relativePath: { eq: "open-hand.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    }      

    negotiate: file(relativePath: { eq: "negotiate.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    }      
    ,
    strongimage: file(relativePath: { eq: "strong2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    }, 
    lottery: file(relativePath: { eq: "national_lottery.png" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    },
    sunsetimage: file(relativePath: { eq: "sunset.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    },
    choiceimage: file(relativePath: { eq: "choice.png" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    },      
    victimage: file(relativePath: { eq: "vict.png" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    },        
  }
`












